import React from "react";
import "./SectionHeading.scss";

const SectionHeading = (props) => {
  return (
    <div className="section-heading">
      <div className="section-heading-title">
        <h2 className="title">{props.title}</h2>
        <p className="lead">{props.lead}</p>
      </div>
    </div>
  );
};

export default SectionHeading;
