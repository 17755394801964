import React from "react";
import Layout from "./../components/Layout";
import SectionHeading from "./../components/SectionHeading";
import SectionInner from "../components/SectionInner";
import Card from "./../components/Card";
import LinkBtn from "../components/LinkBtn";
import "./index.scss";
import Section01 from "./../images/section_01.png";
import Section02 from "./../images/section_02.png";
import Section03 from "./../images/section_03.png";
import Product01 from "./../images/product_01.png";
import Product02 from "./../images/product_02.png";
import Product03 from "./../images/product_03.png";

const IndexPage = () => {
  return (
    <Layout>
      {/* fv */}
      <section className="fv">
        <div className="container">
          <h1 className="fv-txt">
            Sample Text
            <br />
            Sample
          </h1>
          <p className="fv-lead">サンプルテキスト、サンプルテキストさんぷる</p>
          <a
            className="fv-link"
            href="https://jp.freepik.com/vectors/money"
            target="_blank"
            rel="noreferrer"
          >
            Macrovector - jp.freepik.com によって作成された money ベクトル
          </a>
        </div>
      </section>
      {/* overview */}
      <section className="overview">
        <div className="container">
          <SectionHeading title="OVERVIEW" lead="概要" />
          <SectionInner
            image={Section01}
            alt="Section01"
            title="もしくはしかも肝画に詰めのはどう貧乏と云ったば、その害には好かならてって作物を知れとかねなけれます。"
            lead="その後国民のためそんな精神はそれ上をなれるますかと木下さんをするましょた、金力のたくさんですというお批評ますですですが、世の中の時が二つに絶対などの秋刀魚に十月知れけれどもいや、ちょっとの当時が見つかりてこうした限りにつるつる思うでませと歩くで事たが、若いうたてちょっとお長靴見えますのでうる。"
          />
        </div>
      </section>
      {/* about us */}
      <section className="aboutus">
        <div className="container">
          <SectionHeading title="ABOUT US" lead="私たちについて" />
          <SectionInner
            image={Section02}
            alt="Section02"
            title="もしくはしかも肝画に詰めのはどう貧乏と云ったば、その害には好かならてって作物を知れとかねなけれます。"
            lead="その後国民のためそんな精神はそれ上をなれるますかと木下さんをするましょた、金力のたくさんですというお批評ますですですが、世の中の時が二つに絶対などの秋刀魚に十月知れけれどもいや、ちょっとの当時が見つかりてこうした限りにつるつる思うでませと歩くで事たが、若いうたてちょっとお長靴見えますのでうる。"
          />
          <LinkBtn linkTo="/about" linkText="ABOUT US" />
        </div>
      </section>
      {/* service */}
      <section className="service">
        <div className="container">
          <SectionHeading title="SERVICE" lead="サービス" />
          <div className="service-card">
            <Card
              image={Product01}
              alt="Product01"
              title="WEBサイト制作"
              lead="また理科か不安か講演に考えるですて、結果末がたがなるてくるでためを不議論の事実に構うだろます。"
            />
            <Card
              image={Product02}
              alt="Product02"
              title="アプリ開発"
              lead="また理科か不安か講演に考えるですて、結果末がたがなるてくるでためを不議論の事実に構うだろます。"
            />
            <Card
              image={Product03}
              alt="Product03"
              title="サーバー構築"
              lead="また理科か不安か講演に考えるですて、結果末がたがなるてくるでためを不議論の事実に構うだろます。"
            />
          </div>
          <LinkBtn linkTo="/service" linkText="SERVICE" />
        </div>
      </section>
      {/* contact */}
      <section className="contact">
        <div className="container">
          <SectionHeading title="CONTACT" lead="お問い合わせ" />
          <SectionInner
            image={Section03}
            alt="Serction03"
            title="もしくはしかも肝画に詰めのはどう貧乏と云ったば、その害には好かならてって作物を知れとかねなけれます。"
            lead="その後国民のためそんな精神はそれ上をなれるますかと木下さんをするましょた、金力のたくさんですというお批評ますですですが、世の中の時が二つに絶対などの秋刀魚に十月知れけれどもいや、ちょっとの当時が見つかりてこうした限りにつるつる思うでませと歩くで事たが、若いうたてちょっとお長靴見えますのでうる。"
          />
          <div className="section-btn">
            <LinkBtn linkTo="/contact" linkText="CONTACT" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
