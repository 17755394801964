import React from "react";
import { Link } from "gatsby";
import "./LinkBtn.scss";

const LinkBtn = (props) => {
  return (
    <Link className="linkbtn" to={props.linkTo}>
      {props.linkText}
    </Link>
  );
};

export default LinkBtn;
