import React from "react";
import { Link } from "gatsby";
import "./Header.scss";

const Header = () => {
  return (
    <header>
      <div className="container">
        <Link className="title" to="/">
          PEACH
        </Link>
        <nav className="global-nav">
          <Link to="/" activeClassName="active">
            HOME
          </Link>
          <Link to="/about" activeClassName="active">
            ABOUT US
          </Link>
          <Link to="/service" activeClassName="active">
            SERVICE
          </Link>
          <Link to="/contact" activeClassName="active">
            CONTACT
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
