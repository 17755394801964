import React from "react";
import { Link } from "gatsby";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="name">
          <Link className="name-title" to="/">
            DLMOCK
          </Link>
          <a
            className="name-link"
            href="https://dl-web-production.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            WEB PRODUCTION created by Demand-link
          </a>
        </div>
        <div className="map">
          <span className="map-heading">Site Map</span>
          <nav className="map-list">
            <Link to="/">HOME</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/contact">CONTACT</Link>
          </nav>
          <p className="map-lead">
            このサイトは研修や勉強の一貫として制作したサイトです。
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
