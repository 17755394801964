import React from "react";
import "./SectionInner.scss";

const SectionInner = (props) => {
  return (
    <div className="section-inner">
      <img className="section-inner-img" src={props.image} alt={props.alt} />
      <div className="section-inner-txt">
        <h3 className="title">{props.title}</h3>
        <p className="lead">{props.lead}</p>
      </div>
    </div>
  );
};

export default SectionInner;
