import React from "react";
import "./Card.scss";

const Card = (props) => {
  return (
    <div className="card">
      <img className="card-image" src={props.image} alt={props.alt} />
      <span className="card-title">{props.title}</span>
      <p className="card-lead">{props.lead}</p>
    </div>
  );
};

export default Card;
